import styled from 'styled-components';

export const P = styled.p`
  display: block;
  color: ${Styles.color.grey.four};
  font-size: 0.9375rem;
  margin: 0;
  padding: 0.1rem 0;
  a:any-link {
    text-decoration: none;
    color: ${Styles.color.grey.three};
  }
`;

export const Header = styled.h2`
  color: ${Styles.color.white};
  font-size: 1.25rem;
  margin-block-start: 0;
  margin-block-end: 10px;
  text-transform: uppercase;

  ${Styles.desktop} {
    margin-block-end: auto;
  }
`;

export const Subheader = styled.div`
  color: ${Styles.color.white};
`;
