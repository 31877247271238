// This file is generated via scripts/build-static-config.js
  // Do not modify directly as changes will be overwritten in CI

  export const CONFIGURATION = {
    country: 'ch',
    brand: 'fhs',
    env: 'prod',
    graphqlEnv: 'prod',
    graphqlGatewayEnv: '',
    sanityEnv: 'prod',
    platform: 'web',
    commitRef: '01136ee5ab166b5ce469cd1922f9d9b269ec87a0',
    releaseTagRef: 'b434f8e',
    releaseTimestamp: '1737706161',
    sanityDataset: '',
    sanityProjectId: 'czqk28jt',
    appFlowBuildId: '',
    clickTrackingDomain: '',
    adyen: {
      clientKey: '',
      environment: '',
      urlPrefix: '',
      ideal: {
        idealBanks: [],
      }
    },
    apiKeys: {
      amplitude: '240baad0d21ed4816848afeba3874b7c',
      branch: 'key_test_chHDtquTITUXZRU7sxsdSdanCDh1U5se',
      braze: '5f66831c-97f6-479f-adfc-8135d353bf9f',
      brazeCluster: 'sdk.fra-01.braze.eu',
      bringg: 'ue4g_ZxVPR4VkGVyfAKRwKGq1',
      googleMaps: 'AIzaSyBvZN38wRKJpfmAt7qIFYv8DcIhsTJTDV0',
      tomtom: '',
      launchDarkly: '644687644b9d9412dd2dc698',
      launchDarklyMobileKey: 'mob-28b60e7f-d254-4826-aee7-7424f2f3a803',
      mParticle: 'eu1-c8d9578cc2d0654e80571022536689de',
      amplitudeCdpKey: '',
      amplitudeCdpRegion: '',
    },
    apple: {
      merchantId: 'merchant.com.firehouse.prelive',
      migrationMerchantId: '',
      paymentsNetworks: ['AmEx','MasterCard','Visa','Discover'],
      prepaidMerchantId: '',
      migrationPrepaidMerchantId: '',
      socialLogin: {
        clientId: '',
        redirectURI: '',
      },
    },
    branch: {
      domains: []
    },
    checkoutDotCom: {
      publicKey: '',
    },
    facebook: {
      socialLogin: {
        appId: '',
        redirectURI: '',
      },
    },
    aws: {
      gqlApiUrl: 'https://euc1-prod-fhs.rbictg.com/graphql',
      gqlGatewayApiUrl: 'https://euc1-prod-fhs-gateway.rbictg.com/graphql',
      region: 'eu-central-1',
      userPoolClientId: '5q3t4as1h2u5194tdv7fg1dg43',
      userPoolId: 'eu-central-1_Dgq87I60J',
    },
    bringg: {
      region: '6',
    },
    datadog: {
      logs: {
        clientToken: 'pub5560d3ea0973282cab306c73833e35d8',
        dataCenter: 'datadoghq.eu',
      },
      rum: {
        applicationId: 'd2ad1579-fd2d-4500-a5d1-baa39289ebe4',
        clientToken: 'pub5560d3ea0973282cab306c73833e35d8',
        dataCenter: 'datadoghq.eu',
      },
    },
    firstData: {
      corporateStoreId: '1236',
    },
    forter: {
      siteId: 'idnotset',
    },
    google: {
      paymentsEnvironment: 'test',
      paymentsGateway: 'vantiv',
      marketPaymentsGatewayMerchantId: '',
      paymentsGatewayMerchantId: {
        ca: '700000013947',
        us: 'Zv2WjWCbud4BgWqD',
        ch: '',
      },
      migrationPaymentsGateway: '',
      migrationPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      paymentsNetworks: ['AMEX','MASTERCARD','VISA','DISCOVER','JCB'],
      prepaidPaymentsGateway: '',
      prepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      migrationPrepaidPaymentsGateway: '',
      migrationPrepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      socialLogin: {
        clientId: '',
        redirectURI: '',
      },
    },
    googleAdManager: {
      campaignId: '',
    },
    googleTagManager: {
      containerId: 'GTM-59M3GG6',
    },
    oneTrust: {
      cookieCompliance: {
        consentCategoryBranch: '',
        consentCategoryBraze: '',
        consentCategoryMParticle: '',
        globalConsentState: '',
        scriptUrl: '',
        scriptId: '',
      },
      deleteAccountUrl: '',
    },
    pypestream: {
      scriptUrl: '',
      appId: '',
    },
    restaurants: {
      displayEnv: 'prod',
      validMobileOrderingEnvs: ['alpha','beta','live'],
    },
    urls: {
      braze: 'sdk.iad-05.braze.com',
      webBaseUrl: '',
    },
    tokenEx: {
      publicKey: '',
    },
    vrPayment: {
      braintreeTokenizationKey: '',
    },
    orbital: {
      baseUrl: 'https://safetechpageencryptionvar.chasepaymentech.com',
      subscriberId: '100000000005',
      pieFormat: '64',
      mode: 'FPE',
    },
    timeFormat: 'HH:mm',
    dateFormat: 'dd/MM/yyyy',
    addressFormat: 'name_number',
    maxCartQuantity: '',
    appStoreDeeplink: '',
    iosDeepLink: '',
    androidDeepLink: '',
    cookieBanners: {
      cookieBanner:'oneTrust',
      scriptId: '',
      oneTrust: {
        cookieCompliance: {
          consentCategoryBranch: 'C0004',
          consentCategoryBraze: 'C0003',
          consentCategoryMParticle: 'C0001',
          consentCategoryDdRum: 'C0002',
          globalConsentState: 'Opt-In',
          scriptUrl: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
          scriptId: '0193d473-27a4-755a-b8f9-c727f6fcf7e6',
        },
      },
    }
  };